import Matomo from '@components/Matomo';
import Script from 'next/script';
import React from 'react';

const CustomScripts = () => {
  return (
    <>
      <Matomo />
      <script
        async
        defer
        src="https://stable.loyjoy.com/widget.js?process=64f3ec7a-20cf-4c61-b21e-f9cf5b2e6507"
      ></script>
    </>
  );
};

export default CustomScripts;
